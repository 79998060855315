<template>
  <div>
    <div class="item_view" v-for="(item, index) in dataList" :key="`key_${index}`">
      <div class="title_view">待解决问题{{ index + 1 }}</div>
      <div class="content_tx">
        <div class="tx_title">内容:</div>
        <div class="tx_content">{{ item.issue || "-" }}</div>
      </div>
      <div class="content_tx">
        <div class="tx_title">类目:</div>
        <div class="tx_content">{{ item.category || "-" }}</div>
      </div>
      <div class="content_tx">
        <div class="tx_title">关注点:</div>
        <div class="tx_content">{{ item.concern_point || "-" }}</div>
      </div>
      <div class="content_tx">
        <div class="tx_title">发生时间:</div>
        <div class="tx_content">{{ item.time || "-" }}</div>
      </div>
      <div class="content_tx">
        <div class="tx_title">商品ID:</div>
        <div v-if="!item.skus || !item.skus.length" class="tx_content">-</div>
        <div v-else class="tx_content link_tx">
          <span @click="openSpuAction(skuItem)" v-for="(skuItem, idx) in item.skus" :key="`key_${idx}`">{{ skuItem.spu_id + '  ' }}</span>
        </div>
      </div>
      <div class="table_view" style="margin-top: 10px;border-bottom: none;">
        <div class="cell_item title_cell_item">来源</div>
        <div class="cell_item title_cell_item">提及次数</div>
      </div>
      <div class="table_view" v-if="item.sourceList.length == 0">
        <div class="cell_item ">-</div>
        <div class="cell_item ">-</div>
      </div>
      <div class="table_view" v-for="(obj, idx) in item.sourceList" :key="`child_${idx}`">
        <div class="cell_item ">{{ obj.name }}</div>
        <div class="cell_item ">{{ obj.num }}</div>
      </div>
    </div>
    <el-empty v-if="dataList.length == 0" description="暂无数据" :image="require('@/assets/icon_empty.png')"></el-empty>
  </div>
</template>

<script>
import API from '@/common/axios';
export default {
  data() {
    return {
      dataList: []
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      API({
        url: "http://aiapi.biyao.com/llm/v1/model/user/problem",
        data: {
          externalUserId: this.$parent.selectUserInfo.externalUserid
        }
      }).then(res => {
        if (res.code == 200) {
          this.dataList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    openSpuAction(skuItem) {
      if (!skuItem.sku_id) {
        return;
      }
      window.open(`https://www.biyao.com/products/${skuItem.sku_id}-0.html`, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.item_view {
  padding: 20px;
  color: #333;
  border-bottom: 1px solid var(--boder-color);

  .title_view {
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
  }

  .content_tx {
    padding: 10px 0;
    font-size: 14px;
    display: flex;
    line-height: 20px;
    border-bottom: 1px dashed var(--boder-color);

    .tx_title {
      width: 70px;
      overflow: hidden;
    }

    .tx_content {
      width: calc(100% - 70px);
      text-align: justify;
      word-break: break-all;
    }

    .link_tx {
      color: #409EFF;
      cursor: pointer;
    }
  }

  .table_view {
    border: 1px solid var(--boder-color);
    display: flex;
    width: 100%;

    .cell_item {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      font-size: 12px;
      color: #333;
      line-height: 16px;
      word-break: break-all;
      text-align: justify;
      padding: 4px;

      &:last-child {
        border-left: 1px solid var(--boder-color);
      }
    }

    .title_cell_item {
      line-height: 24px;
      font-size: 14px;
      background-color: #ccc;
      font-weight: bold;
    }
  }
}
</style>